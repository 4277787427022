import { StaticImage } from 'gatsby-plugin-image'
import React, { ReactElement, useEffect } from 'react'
import Theme, { Layout, SectionHero, SEO } from '../components'
import page from '../data/pages/helpnow-kontakt.yml'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import '../styles/contactFormular.css'
import { SectionKontaktFormular } from '../components/Section/SectionKontaktFormular'

declare global {
  interface Window {
    hbspt: any
  }
}

export default function KontaktPage(): ReactElement {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '7744677',
          formId: 'cdfe21ee-57f5-41c5-bf4c-acddf965579d',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <>
      <SEO title={page?.title} />
      <div
        className="fixed z-0 w-full h-full top-0 left-0 "
        style={{
          mixBlendMode: 'screen',
          opacity: '0.8',
          backdropFilter: 'blur(3px)',
        }}
      >
        <StaticImage
          src="../images/jellyfish.jpg"
          alt="Quallen"
          objectFit="cover"
          className="w-full h-full select-none"
          draggable={false}
        />
      </div>
      <Theme>
        <Layout>
          <SectionHero
            title="Mehr über payactive helpnow erfahren"
            titleTag="h1"
            subtitle="Möchtest du mehr über payactive helpnow erfahren? Sende uns eine Nachricht über das untenstehende Kontaktformular und wir melden uns bei dir!"
            containerClass="pb-0 mb-0"
          />

          <SectionKontaktFormular
            title="Kontaktformular"
            bulletsTitle="Wie können wir helfen?"
            formular={<div id="hubspotForm" />}
            bullets={[
              {
                title: 'Allgemeines zum Spendentool?',
              },
              {
                title:
                  'Payactive helpnow für deine NGO oder dein Unternehmen anfragen?',
              },
            ]}
          />

          <SectionNewsletter containerClass="" page="Startseite" />
        </Layout>
      </Theme>
    </>
  )
}
