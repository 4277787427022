import React from 'react'
import { Section, SectionProps } from './Section'
import { Container } from '../Layout'
import check from '../../images/check.svg'
import { Headline } from '../Typography'

export type SectionKontaktFormularProps = SectionProps & {
  title?: string | null
  bulletsTitle?: string | null
  containerClass?: string
  formular?: React.ReactElement | null
  bullets?: Array<{
    title: string
    text?: string
  }> | null
}

const defaultProps: Partial<SectionKontaktFormularProps> = {
  title: null,
  bulletsTitle: null,
  bullets: null,
  formular: null,
  containerClass: '',
}

const SectionKontaktFormular = (
  props: SectionKontaktFormularProps
): React.ReactElement => {
  const { bullets, formular, title, bulletsTitle, containerClass } = props

  return (
    <Section containerClass={containerClass}>
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-5 items-center">
          <div className="lg:col-span-2 relative">
            {title && (
              <h3 className="form-title text-2xl absolute left-5 top-2 md:top-5">
                {title}
              </h3>
            )}
            {formular}
          </div>
          <div className="lg:col-span-1">
            <Headline
              tagName="h3"
              fontSize="small"
              variant="clean"
              className="opacity-100 px-5"
            >
              {bulletsTitle}
            </Headline>

            {bullets && (
              <div className="bg-opacity-10 rounded-xl px-5 py-5">
                <ul className="space-y-4">
                  {bullets.map(({ title: bulletTitle }) => (
                    <li className="flex gap-4">
                      <img src={check} alt="Haken" className="w-8 h-8 mt-1.5" />
                      <div className="self-center">
                        <span className="font-normal block">{bulletTitle}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Section>
  )
}
SectionKontaktFormular.defaultProps = defaultProps

export { SectionKontaktFormular }
